import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Buttons from "../components/buttons"
import Layout from "../components/layout"
import Seo from "../components/seo"
import image from "../../static/PackshotStill_Inferno.jpg"
import { navigate } from "gatsby-link"
const inferno = () => (
  <Layout>
    <Seo title="Kahvisi on Löfbergs Inferno." image={image} />
    <div className="result" id="1">
      <h3> Olet oikea titaani. </h3>
      <p>
        Vahvimmista vahvin. Fyysisen voiman lisäksi sinussa on sisua ja
        sitkeyttä, jotka vievät sinut läpi vaikka harmaan kiven. Kovan kuoresi
        alta kumpuaa kuitenkin erityistä lämpöä, josta läheisesi saavat aina
        osansa.
        <br />
        <br />
        Kahvisi on Löfbergs Inferno. Extratumma, intensiivinen ja täyteläinen.
      </p>
      <StaticImage
        src="../assets/PackshotStill_Inferno.jpg"
        alt="Löfbergs Inferno"
      />
      <Buttons
        quote="Kahvini on Löfbergs Inferno. Extratumma, intensiivinen ja täyteläinen."
        url={typeof window !== "undefined" ? window.location.href + "?fb" : ""}
      />
    </div>
    {typeof window !== "undefined"
      ? window.location.href.includes("?fb") && navigate("/")
      : ""}
  </Layout>
)

export default inferno
