// import { Link } from "gatsby"
import React from "react"
import { FacebookShareButton } from "react-share"
import { Link } from "gatsby"
const Buttons = props => {
  return (
    <>
      {" "}
      {/* <Link className="button" to="/lomake">
        Täytä yhteystietosi ja osallistu arvontaan
      </Link> */}
      <FacebookShareButton
        className="button"
        quote="Testasin mikä kahvi sopii minulle. Testaa sinäkin ja löydä vahvuutesi. "
        resetButtonStyle={false}
        url={props && props.url}
      >
        Jaa tuloksesi sosiaalisessa mediassa
      </FacebookShareButton>
      <Link className="button" to="/testi#q">
        Tee testi uudelleen
      </Link>
      {/* <a className="button" href="https://www.lofbergs.fi/tuotteet/">
        Tutustu tuotteisiimme
      </a> */}
    </>
  )
}

export default Buttons
